import React, { useState } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import axios from 'axios';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import styles from '../styles/index.module.scss';

const AntdSelect = dynamic(() => import('antd/lib/select'), { ssr: false });

const Home = ({ initialCalibers, showSnackbar }) => {
  const router = useRouter();
  const [calibers, setCalibers] = useState(initialCalibers.calibers);
  const [caliber, setCaliber] = useState('/');
  const [allCalibers, setAllCalibers] = useState(initialCalibers.allCalibers);
  const [isSortedAsc, setIsSortedAsc] = useState({
    Handgun: true,
    Shotgun: true,
    Rifle: true,
    Rimfire: true,
  });

  const onChange = (value) => {
    setCaliber(value);
  };

  const navigateLink = () => {
    if (!caliber || caliber === 'undefined') {
      showSnackbar('Please select a valid caliber.', 'error');
      return;
    }
  
    const data = initialCalibers.calibers.find((e) => e.value == caliber);
  
    if (!data) {
      showSnackbar(`No matching caliber found for id: ${caliber}`, 'error');
      return;
    }
  
    const typePart = data.type ? data.type.split(' ')[0].toLowerCase() : 'defaultType';
    const urlName = data.label.includes("&") ? encodeURIComponent(data.url_name) : data.url_name;
  
    if (!urlName) {
      showSnackbar(`URL name is undefined for caliber: ${caliber}`, 'error');
      return;
    }
  
    const targetUrl = `/browse-components/${typePart}?caliber=${urlName}`;
    console.log('Navigating to:', targetUrl);
    router.push(targetUrl);
  };
  
  

  const toggleSort = (type) => {
    setIsSortedAsc((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const sortedCalibers = (type) => {
    return [...allCalibers[type]].sort((a, b) => {
      return isSortedAsc[type] ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
    });
  };

  return (
    <div className={styles.home}>
      <Head>
        <title>AmmoHawk Ammo Search Engine</title>
        <meta name="description" content="The best ammo deals, all in one place." />
        <meta name="title" content="AmmoHawk Search Engine" />
        <meta name="description" content="Find the best ammunition prices and availability from top ammo suppliers and vendors | 5.56 9mm .308 12 gauge 300 blackout" />
        <meta name="h1" content="AmmoHawk Search Engine" />
        <link rel="canonical" href="https://ammohawk.com/" />
      </Head>
      <div className={styles.home__details}>
        <h1>AmmoHawk Search Engine</h1>
        <p>The best ammo deals, all in one place.</p>
      </div>
      <div className={styles.home__search}>
        {calibers.length > 0 && (
          <AntdSelect
            className={styles.antSelect}
            showSearch
            placeholder="Type Caliber to find available ammo"
            optionFilterProp="children"
            onChange={onChange}
            placement="bottomLeft"
            filterOption={(input, option) => {
              const inputWords = input.toLowerCase().split(/\s+/);
              const label = (option?.label ?? '').toLowerCase();
              return inputWords.every(word => label.includes(word));
            }}
            options={calibers}
          />
        )}
        <button onClick={navigateLink}>Search</button>
      </div>
      <div className={styles.home__types}>
        {['Handgun', 'Shotgun', 'Rifle', 'Rimfire'].map(type => (
          <section key={type}>
            <div onClick={() => router.push(`/browse-components/${type.toLowerCase()}`)} className={styles.home__type}>
              {type}
            </div>
            <header>
              Calibers
              <div onClick={() => toggleSort(type)} className={styles.sortIcon}>
                {isSortedAsc[type] ? <FaSortAlphaDown /> : <FaSortAlphaUp />}
              </div>
              <ul>
                {sortedCalibers(type)
                  .slice(0, 5)
                  .map((e, ind) => (
                    <li key={ind} className={styles.caliberItem}>
                      <Link href={`/browse-components/${e.url_type}?caliber=${encodeURIComponent(e.url_name)}`}>
                        <span className={styles.caliberBullet}>-</span> {e.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </header>
          </section>
        ))}
      </div>
    </div>
  );
};

export async function getStaticProps() {
  try {
    const res = await axios.get('https://backend.ammohawk.com/api/products/calibers');
    const calibers = res.data.map((caliber) => ({
      value: caliber.id,
      label: caliber.name,
      type: caliber.type,
      url_name: caliber.url_name,
      url_type: caliber.url_type
    }));

    const requests = [
      axios.get(`https://backend.ammohawk.com/api/products/calibers?type=Handgun Ammunition&top=true`),
      axios.get(`https://backend.ammohawk.com/api/products/calibers?type=Shotgun&top=true`),
      axios.get(`https://backend.ammohawk.com/api/products/calibers?type=Rifle Ammunition&top=true`),
      axios.get(`https://backend.ammohawk.com/api/products/calibers?type=Rimfire Ammunition&top=true`)
    ];
    const [handgun, shotgun, rifle, rimfire] = await Promise.all(requests);

    const mapCalibers = (calibers) => calibers.map(caliber => ({
      id: caliber.id,
      name: caliber.name,
      type: caliber.type,
      top: caliber.top,
      url_name: caliber.url_name,
      url_type: caliber.url_type
    }));

    return {
      props: {
        initialCalibers: {
          calibers,
          allCalibers: {
            Handgun: mapCalibers(handgun.data),
            Shotgun: mapCalibers(shotgun.data),
            Rifle: mapCalibers(rifle.data),
            Rimfire: mapCalibers(rimfire.data),
          },
        },
      },
      revalidate: 10, // Revalidate at most every hour
    };
  } catch (err) {
    console.error('Failed to fetch calibers:', err);
    return {
      props: {
        initialCalibers: {
          calibers: [],
          allCalibers: {
            Handgun: [],
            Shotgun: [],
            Rifle: [],
            Rimfire: [],
          },
        },
      },
    };
  }
}

export default Home;
